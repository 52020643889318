import React from 'react';

const NotSkinBorder = (props: React.PropsWithChildren<React.SVGProps<SVGSVGElement>>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="70"
    height="70"
    viewBox="0 0 70 70"
  >
    <defs>
      <clipPath>
        <rect width="51.084" height="46.931" fill="currentColor" />
      </clipPath>
    </defs>
    <g transform="translate(-1173 -1697)">
      <g transform="translate(362 1426)">
        <g transform="translate(811 271)" fill="none" stroke="currentColor" strokeWidth="1">
          <rect width="70" height="70" stroke="none" />
          <rect x="0.5" y="0.5" width="69" height="69" fill="none" />
        </g>
      </g>
      <g transform="translate(1182.458 1708.534)">
        <rect
          width="2.359"
          height="56.775"
          transform="translate(5.235 1.41) rotate(-36.698)"
          fill="currentColor"
        />
        <g>
          <g clipPath="url(#clip-path)">
            <path
              d="M84.371,23.756a7.448,7.448,0,0,1-7.4-7.541,11.7,11.7,0,0,1,1.041-3.937A90.522,90.522,0,0,1,83.755.772C84.082.2,84.61.166,84.918.712A81.163,81.163,0,0,1,91.315,14a7.312,7.312,0,0,1-5.269,9.517c-.549.121-1.116.164-1.675.244m.013-21.649c-.173.3-.274.468-.369.641a80.965,80.965,0,0,0-5.1,10.537,7.815,7.815,0,0,0-.666,4.129,6.242,6.242,0,0,0,7.493,4.947A6.092,6.092,0,0,0,90.3,14.849a40.505,40.505,0,0,0-3.02-7.207c-.917-1.823-1.891-3.618-2.9-5.535"
              transform="translate(-58.813 -0.246)"
              fill="currentColor"
            />
            <path
              d="M25.593,109.273H50.019a1.314,1.314,0,0,1,.59.031c.2.116.463.339.474.529s-.225.431-.408.584c-.1.087-.319.056-.484.056q-24.651,0-49.3,0c-.165,0-.38.031-.484-.057-.182-.154-.417-.4-.4-.587s.273-.411.477-.526a1.322,1.322,0,0,1,.59-.031H25.593"
              transform="translate(0.001 -83.486)"
              fill="currentColor"
            />
            <path
              d="M8.407,83.366q-3.72,0-7.44,0c-.166,0-.378.046-.487-.037-.2-.154-.474-.39-.469-.586s.3-.4.5-.553c.1-.073.291-.024.44-.024H15.833c.707,0,1.011.188,1,.613-.014.405-.318.589-.983.589H8.407"
              transform="translate(-0.008 -62.772)"
              fill="currentColor"
            />
            <path
              d="M153.708,82.176q3.72,0,7.44,0c.166,0,.369-.044.49.034.189.121.447.329.448.5a.961.961,0,0,1-.386.6c-.116.1-.35.063-.531.063q-7.465,0-14.929,0h-.1c-.571-.008-.867-.21-.875-.6s.283-.6.855-.6q3.795,0,7.589,0"
              transform="translate(-111.001 -62.785)"
              fill="currentColor"
            />
            <path
              d="M73.413,130.177a2.383,2.383,0,0,1,2.4,2.409,2.4,2.4,0,1,1-4.793-.045,2.382,2.382,0,0,1,2.394-2.363m1.2,2.377a1.2,1.2,0,1,0-2.39.045,1.2,1.2,0,0,0,2.39-.045"
              transform="translate(-54.268 -99.473)"
              fill="currentColor"
            />
            <path
              d="M127.682,130.176a2.375,2.375,0,0,1,2.384,2.42,2.4,2.4,0,1,1-2.384-2.42m1.182,2.393a1.176,1.176,0,0,0-1.2-1.191,1.189,1.189,0,0,0-1.19,1.205,1.195,1.195,0,0,0,2.389-.014"
              transform="translate(-95.726 -99.472)"
              fill="currentColor"
            />
            <path
              d="M19.177,130.176a2.392,2.392,0,0,1,2.386,2.425,2.4,2.4,0,1,1-4.792-.078,2.372,2.372,0,0,1,2.405-2.347m-1.2,2.4a1.205,1.205,0,0,0,1.216,1.186,1.218,1.218,0,0,0,1.172-1.186,1.192,1.192,0,0,0-1.2-1.2,1.172,1.172,0,0,0-1.189,1.2"
              transform="translate(-12.816 -99.472)"
              fill="currentColor"
            />
            <path
              d="M46.365,155.964a2.381,2.381,0,0,1,2.329,2.475,2.4,2.4,0,1,1-4.792-.183,2.382,2.382,0,0,1,2.463-2.292m-.051,3.575a1.188,1.188,0,1,0-.058-2.374,1.179,1.179,0,0,0-1.153,1.187,1.2,1.2,0,0,0,1.211,1.187"
              transform="translate(-33.546 -119.178)"
              fill="currentColor"
            />
            <path
              d="M100.581,155.964a2.38,2.38,0,0,1,2.341,2.464,2.4,2.4,0,1,1-4.792-.159,2.382,2.382,0,0,1,2.451-2.3m-.033,3.575a1.188,1.188,0,1,0-.07-2.374,1.178,1.178,0,0,0-1.147,1.193,1.2,1.2,0,0,0,1.217,1.181"
              transform="translate(-74.984 -119.178)"
              fill="currentColor"
            />
            <path
              d="M154.793,155.963a2.381,2.381,0,0,1,2.357,2.449,2.4,2.4,0,1,1-4.793-.129,2.381,2.381,0,0,1,2.436-2.32m-.059,3.576a1.2,1.2,0,0,0,1.214-1.184,1.2,1.2,0,1,0-1.214,1.184"
              transform="translate(-116.421 -119.177)"
              fill="currentColor"
            />
            <path
              d="M184.324,132.56a2.407,2.407,0,0,1-2.362,2.4,2.434,2.434,0,0,1-2.428-2.4,2.4,2.4,0,0,1,2.376-2.388,2.367,2.367,0,0,1,2.415,2.386m-2.412-1.183a1.2,1.2,0,0,0-1.177,1.219,1.226,1.226,0,0,0,1.2,1.165,1.2,1.2,0,0,0,1.191-1.208,1.169,1.169,0,0,0-1.21-1.176"
              transform="translate(-137.189 -99.471)"
              fill="currentColor"
            />
            <path
              d="M116.012,63.366a5.75,5.75,0,0,1-2.458,4.421c-.316.229-.639.306-.935-.018-.262-.286-.167-.662.246-.962a4.581,4.581,0,0,0,1.865-2.852,5.876,5.876,0,0,0,.089-.792c.029-.408.159-.728.638-.726.38,0,.573.281.563.78,0,.05,0,.1-.007.15"
              transform="translate(-85.944 -47.71)"
              fill="currentColor"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default NotSkinBorder;
